<template>
  <div>
    <div class="content-box">
      <div class="head-sculpture">
        <ayl-img-upload v-model="personImage" :imgListLength="1"></ayl-img-upload>
        <p style="text-algin: center">头像:150*150</p>
      </div>
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
          :label-width="$config.labelWidth"
        >
          <el-row :gutter="10">
            <!-- <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"></el-col> -->
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="姓名" prop="name">
                <el-input v-model="form.name" placeholder="请输入" maxlength="10"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="性别" prop="sex">
                <el-select v-model="form.sex" placeholder="请选择">
                  <el-option
                    v-for="item in $enums_hw.sex.list"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="身份证号码" prop="idCardNumber">
                <el-input
                  v-model="form.idCardNumber"
                  placeholder="请输入"
                  maxlength="18"
                  @blur="onInputBlur($event)"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="出生年月:" prop="birthday">
                <el-input v-model="form.birthday" placeholder="请输入" maxlength="11" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="学历">
                <el-select v-model="form.education" placeholder="请选择">
                  <el-option
                    v-for="item in educationOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="人员类型" prop="personType">
                <el-cascader
                  v-model="form.personType"
                  placeholder="请选择"
                  :options="$enums_hw.personnelTypeNew.list"
                  :show-all-levels="false"
                  @change="handlePersonTypeChange"
                  filterable
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="联系方式" prop="phone">
                <el-input v-model="form.phone" placeholder="请输入" maxlength="11"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="工作状态:">
                <el-select v-model="form.personStatus" placeholder="请选择" disabled>
                  <el-option
                    v-for="item in personStatusOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="籍贯" prop="adminRegions">
                <el-cascader
                  v-model="form.adminRegions"
                  size="mini"
                  :options="provinceAndCityData"
                  @change="handleRegionChange"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="所属标段" prop="bidId">
                <el-cascader
                  v-model="form.bidId"
                  placeholder="请选择"
                  :options="bidList"
                  :show-all-levels="false"
                  filterable
                  @change="bidIdChange"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="所在项目组" prop="projectGroupId">
                <el-input v-model="form.projectGroupId" placeholder="请输入" maxlength="18" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="所属企业:">
                <el-input v-model="form.companyName" placeholder="请输入" maxlength="11" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"></el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="上传附件">
                <ayl-img-upload
                  v-model="faceImg"
                  :imgListLength="1"
                  :imgWidth="'100%'"
                  :imgHeight="'150px'"
                  :uploadDesc="'请上传身份证正面'"
                  :uploadIcon="'avatar-icon iconfont iconzhaopian'"
                ></ayl-img-upload>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label=" ">
                <ayl-img-upload
                  v-model="backImg"
                  :imgListLength="1"
                  :imgWidth="'100%'"
                  :imgHeight="'150px'"
                  :uploadDesc="'请上传身份证正面'"
                  :uploadIcon="'avatar-icon iconfont iconzhaopian'"
                ></ayl-img-upload>
              </el-form-item>
            </el-col>
            <el-col v-if="isDriver==3" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="人脸库照片">
                <ayl-img-upload
                  v-model="faceList"
                  :imgListLength="5"
                  :imgWidth="'150px'"
                  :imgHeight="'150px'"
                ></ayl-img-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="tac" style="margin-top: 40px">
          <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
          <!-- <el-button class="btn-hw" @click="onSubmitAndNext">保存并录入下一条</el-button> -->
          <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData, CodeToText } from "element-china-area-data";
// import addForDialog from "@/mixins/add-for-dialog";
export default {
  // mixins: [addForDialog],
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const validateContactPhone = (rule, value, callback) => {
      if (!value || !/^[1][0-9][0-9]{9}$/.test(value)) {
        callback(new Error("请输入11位手机号码"));
      } else {
        callback();
      }
    };
    return {
      nav: [{ name: "编辑人员" }],
      provinceAndCityData: provinceAndCityData,
      submitLoading: false,
      valueImage: true,
      valueImageF: true,
      valueImageJ: true,
      headImgValue: true,
      bidList: [], //所属标段下拉列表
      educationOptions: [
        { value: 0, label: "小学" },
        { value: 1, label: "初中" },
        { value: 2, label: "高中" },
        { value: 3, label: "中专" },
        { value: 4, label: "大专" },
        { value: 5, label: "本科" },
        { value: 6, label: "硕士" },
      ],
      personStatusOption: [
        { value: 0, label: "在职" },
        { value: 1, label: "离职" },
      ],
      isDriver: null,
      form: {
        name: null, //姓名
        sex: null, //性别
        idCardNumber: null, //身份证号码
        birthday: null, //出生年月
        education: null, //学历
        personType: null, //人员类型
        phone: null, //联系方式
        personStatus: 0, //工作状态
        province: null, //省份
        city: null, //城市
        bidId: null, //所属标段
        companyName: null, //所属企业
        projectGroupId: null, //所在项目组
        idImageUrls: [], //身份证照片
        personImageUrls: null, //个人证件照
        adminRegions: null, // 籍贯
      },

      // 头像
      personImage: [],
      // 身份证
      backImg: [],
      faceImg: [],
      // 人脸库
      faceList: [],
      rules: {
        name: [
          {
            min: 1,
            max: 10,
            required: true,
            message: "请输入1-10位姓名",
            trigger: "blur",
          },
          { validator: this.$validator.isContainBlank },
        ],
        sex: { required: true, message: "请选择性别", trigger: "change" },
        idCardNumber: [
          {
            max: 18,
            required: true,
            message: "请输入18位身份证号码",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              let regExp = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;
              if (!regExp.test(value)) {
                callback(new Error("请输入有效身份证号码"));
              } else {
                callback();
              }
            },
          },
        ],

        phone: [
          { required: true, trigger: "blur", validator: validateContactPhone },
        ],
        adminRegions: {
          required: true,
          message: "请选择籍贯",
          trigger: "change",
        },
        bidId: { required: true, message: "请选择所属标段", trigger: "change" },
      },
      personType: {
        required: true,
        message: "请选择人员类型",
        trigger: "change",
      },
      projectGroupId: {
        required: true,
        message: "不能为空",
        trigger: "blur",
      },
    };
  },
  methods: {
    //身份证号码截取出生日期
    getBirthday(idCard) {
      let birthday = "";
      if (idCard.length == 18) {
        birthday = idCard.substr(6, 8);
        return birthday.replace(/(.{4})(.{2})/, "$1-$2-");
      } else if (idCard.length == 15) {
        birthday = "19" + idCard.substr(6, 6);
        return birthday.replace(/(.{4})(.{2})/, "$1-$2-");
      } else {
        return "";
      }
    },
    //身份证号码输入框移出事件
    onInputBlur(event) {
      if (
        event.target.value.length !== 18 &&
        event.target.value.length !== 15
      ) {
        return;
      } else {
        this.form.birthday = this.getBirthday(event.target.value);
      }
    },
    /**
     * 籍贯
     */
    handleRegionChange(value) {
      this.form.province = value[0];
      this.form.city = value[1];
    },
    /**
     * 人员列表
     */
    handlePersonTypeChange(val) {
      this.form.personType = val[val.length - 1];
      console.log(val);
      this.isDriver = val[0];
    },
    // 获取标段
    async bidIdChange(val) {
      this.form.bidId = val[1];
      // 获取所在项目组
      await this.$api_hw.common_bidManageGet({ id: val[1] }).then((res) => {
        console.log(res);
        this.form.projectGroupId = res.projectGroupId;
      });
    },

    //关闭
    onClose() {
      this.$emit("onClose"); //关闭弹窗
    },
    // 提交
    async submit() {
      let params = JSON.parse(JSON.stringify(this.form));
      params.idImageUrls = [this.faceImg[0], this.backImg[0]].filter((item) => {
        if (item) {
          return item;
        }
      });
      delete params.adminRegions;
      params.personImageUrls = this.personImage[0];
      await this.$api_hw
        .personnelInfo_personUpdate({
          sanitationPersonManageVO: params,
        })
        .then((res) => {
          this.$notify({
            title: "成功",
            message: `${this.nav[0].name}成功`,
            type: "success",
          });
          BUS.$emit(BUSEVENT.REFRESH_PERSONNEL_INFO);
          this.onClose();
          if (isGoNext !== true) {
            this.$refs["form"].resetFields();
          } else {
            this.form = {};
            this.$refs["form"].resetFields();
          }
        });
    },
    // 提交表单
    onSubmit(isGoNext) {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;
        this.submitLoading = true;
        try {
          await this.submit();
        } catch (e) {
          // console.info(e);
        } finally {
          this.submitLoading = false;
        }
      });
    },
    onSubmitAndNext() {
      // console.log(onSubmitAndNext);
    },
  },

  async mounted() {
    //获取所属标段下拉
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});

    // 获取所属企业
    await this.$api_hw.common_getTenantCompanyInfo({}).then((res) => {
      this.form.companyId = res.value;
      this.form.companyName = res.label;
    });

    // 获取详情+数据绑定
    let formData = await this.$api_hw.personnelInfo_personGetDetail({
      id: this.dataFromDialog.id,
    });
    console.log(formData);
    this.form = JSON.parse(JSON.stringify(formData));
    this.form.adminRegions = [formData.province, formData.city];
    // console.log(this.form)
    this.faceImg = formData.idImageUrls[0] ? [formData.idImageUrls[0]] : [];
    this.backImg = formData.idImageUrls[1] ? [formData.idImageUrls[1]] : [];

    this.personImage = formData.personImageUrls
      ? [...[formData.personImageUrls]]
      : [];
    console.log(this.personImage);
  },
};
</script>

<style lang='sass' scoped>
.content-box
  display: flex
  flex-wrap: nowrap
.head-sculpture
  order: 2
  text-align: center

.el-dialog__body
  padding-top: 0 !important
.content-box .content-main
  padding-top: 0
/deep/ el-form
  display: flex
  flex-wrap: wrap
/deep/ .el-form-item
  display: flex
  width: 100%
/deep/ .el-date-editor.el-input,
/deep/ .el-range-editor--mini.el-input__inner,
/deep/ .el-cascader--mini,
/deep/ .el-select
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1;
/deep/ .el-form-item--mini .el-form-item__label 
  text-align: right;
  width: 130px;
</style>
